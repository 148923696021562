/* eslint-disable @typescript-eslint/camelcase */
import { UserManagerSettings } from 'oidc-client';

interface SigningKey {
  kty: string;
  use: string;
  alg: string;
  kid: string;
  x5t: string;
  n: string;
  e: string;
  x5c: string[];
}

interface EnvironmentConfiguration {
  clientId: string;
  clientSecret: string;
  rootUrl: string;
  signingKeys: SigningKey[];
}

export const authLoginCallbackPath = '/auth-login-callback';

const createConfiguration = (
  env: EnvironmentConfiguration
): UserManagerSettings => ({
  authority: env.rootUrl,
  client_id: env.clientId,
  client_secret: env.clientSecret,
  redirect_uri: `https://${window.location.host}${authLoginCallbackPath}`,
  response_type: 'code',
  scope: 'openid',
  loadUserInfo: false,
  metadata: {
    issuer: `${env.rootUrl}`,
    authorization_endpoint: `${env.rootUrl}/oauth2/authorize`,
    token_endpoint: `${env.rootUrl}/oauth2/token`,
    userinfo_endpoint: `${env.rootUrl}/userinfo`,
    end_session_endpoint: `${env.rootUrl}/oauth2/logout`,
    jwks_uri: `${env.rootUrl}/discovery/keys`,
  },
  signingKeys: env.signingKeys,
});


export const lassoProductionConfiguration = createConfiguration({
  clientId: 'da98c7a8-9724-408a-862f-b0291747d996',
  rootUrl: 'https://adfs.lasso.labcollab.net/adfs',
  clientSecret: 'y_Rge5PaiCGQQYk8L6TbPNxafVHLpDIayPdVX5yk',
  signingKeys: [
    {
      kty: 'RSA',
      use: 'sig',
      alg: 'RS256',
      kid: 'Kj1RZvzM7wDiougvw0MRosGyT_k',
      x5t: 'Kj1RZvzM7wDiougvw0MRosGyT_k',
      n:
        'sISL93dQmYS1igDDNhZomIVmabOZqgKLADnT99ygzZ7Cvp3uazyHZsSmU8WW7dsO32B3Afy0rPGH67JmOU-qIwfGKSDOJ4-oIDRJJHLHU9L9ZNLsWKmdDudp7PIA00_mrEx7zLWcCQqRVHzheKsn1BVq_025qtHVpjZN3BPdUWXZC--uhCu9aM5Ifge0sAflaSSCJU3285UpZYylSu0n8mAFO3fjri31kbsoW5UGxEAEZiDSvQnmOxHBum-4RHeQem1wkDlc_uLu2Xzskd9FkZGiyJTAx031NEG25sGNPypl_KM8v-EkcbDaHiLkWjfQVfL0wnk5T-5_AQVC0Jn-jQ',
      e: 'AQAB',
      x5c: [
        'MIIFejCCBGKgAwIBAgIQDbrfrEhlka9Hy7K2BnNqUTANBgkqhkiG9w0BAQsFADBGMQswCQYDVQQGEwJVUzEPMA0GA1UEChMGQW1hem9uMRUwEwYDVQQLEwxTZXJ2ZXIgQ0EgMUIxDzANBgNVBAMTBkFtYXpvbjAeFw0yMDA3MDIwMDAwMDBaFw0yMTA2MDUxMjAwMDBaMCMxITAfBgNVBAMTGGFkZnMubGFzc28ubGFiY29sbGFiLm5ldDCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBALCEi/d3UJmEtYoAwzYWaJiFZmmzmaoCiwA50/fcoM2ewr6d7ms8h2bEplPFlu3bDt9gdwH8tKzxh+uyZjlPqiMHxikgziePqCA0SSRyx1PS/WTS7FipnQ7naezyANNP5qxMe8y1nAkKkVR84XirJ9QVav9NuarR1aY2TdwT3VFl2QvvroQrvWjOSH4HtLAH5WkkgiVN9vOVKWWMpUrtJ/JgBTt3464t9ZG7KFuVBsRABGYg0r0J5jsRwbpvuER3kHptcJA5XP7i7tl87JHfRZGRosiUwMdN9TRBtubBjT8qZfyjPL/hJHGw2h4i5Fo30FXy9MJ5OU/ufwEFQtCZ/o0CAwEAAaOCAoUwggKBMB8GA1UdIwQYMBaAFFmkZgZSoHuVkjyjlAcnlnRb+T3QMB0GA1UdDgQWBBRQ1M79ZkINlSF4yoXkiJkXfJZmxzAjBgNVHREEHDAaghhhZGZzLmxhc3NvLmxhYmNvbGxhYi5uZXQwDgYDVR0PAQH/BAQDAgWgMB0GA1UdJQQWMBQGCCsGAQUFBwMBBggrBgEFBQcDAjA7BgNVHR8ENDAyMDCgLqAshipodHRwOi8vY3JsLnNjYTFiLmFtYXpvbnRydXN0LmNvbS9zY2ExYi5jcmwwIAYDVR0gBBkwFzALBglghkgBhv1sAQIwCAYGZ4EMAQIBMHUGCCsGAQUFBwEBBGkwZzAtBggrBgEFBQcwAYYhaHR0cDovL29jc3Auc2NhMWIuYW1hem9udHJ1c3QuY29tMDYGCCsGAQUFBzAChipodHRwOi8vY3J0LnNjYTFiLmFtYXpvbnRydXN0LmNvbS9zY2ExYi5jcnQwDAYDVR0TAQH/BAIwADCCAQUGCisGAQQB1nkCBAIEgfYEgfMA8QB3APZclC/RdzAiFFQYCDCUVo7jTRMZM7/fDC8gC8xO8WTjAAABcw0+sysAAAQDAEgwRgIhAKd/HTp+xXPtAf3eECqrgq2/Gul3lA3YG9bXTYya0IJkAiEA7z8WlkZmHnCDS7j2OVYtXRAdQBKNX9B9+vPRPiid6GcAdgBc3EOS/uarRUSxXprUVuYQN/vV+kfcoXOUsl7m9scOygAAAXMNPrNfAAAEAwBHMEUCIQDxzCheroBlOxpK7/SSTdaEgTmRS8aAOGHSH7f0sOIhUQIgebACtutqbHPT79SRr7FvVhIhA7ZCJ5MelHwwTczdrFUwDQYJKoZIhvcNAQELBQADggEBADTwaJXJ/92GXCu/HtGmQ0xjRTAHoeljmJm+ZKvZSlnB1jSYT/daXKbb5D/CYwH1FGbVvYveOWW2vhdNyEIAyuFwIGOMRMFtq+J67cEQpXT1pC7G/CvGnE7A/n4cc5UYnAb/0/quNhSacj/RK04Y0fIy6l++SRQC3r6CmF1LwuUirVBq7QHS/8fp3SSoiQB/Pq3byCIPfl5U7LcfAYIJOsZM7plthIe0ofTfzRpblAQGvY0a9uwPK7bB3tjmL/DeSTy24gQnOK38qQ5sGElcADEZEt+Hbzxhog9QIAPwyaCpgidAIoGVVQdb9Mxgs3XLCUuCu5XOrA0QdgJdXeKIuWU=',
      ],
    },
  ],
});