/* eslint-disable no-param-reassign */
import React, {
  useState
} from 'react';
import {
  CoreTable,
  CoreTableSettingsButton,
  useLocalStorage,
} from '@amzn/dots-core-ui';
import Row from '@amzn/meridian/row';
import Alert from '@amzn/meridian/alert';

import { CrashLog, useClient, useGet } from '$/api';
import { CrashModelColumnDefinitions } from './CrashModelColumnDefinitions';


export type CoreSearchTableProps = {
  crash_model_id: string;
};

export const CrashModelSearchTable = ({
  crash_model_id
}: CoreSearchTableProps): JSX.Element => {
  const { crash_models } = useClient();

  const [page, setPage] = useState(1);
  const columns = CrashModelColumnDefinitions;

  const [visibleColumnIds, setVisibleColumnIds] = useLocalStorage(
    `${crash_models.resourceKey}-list-columns`,
    columns.map((col) => col.id)
  );
  const [pageSize, setPageSize] = useLocalStorage(
    `${crash_models.resourceKey}-list-page-size`,
    25
  );
  // TODO use the client here

  const {
    data, error, isError, isFetching
  } = useGet(crash_models.resourceKey, crash_models.get, crash_model_id);

  if (isError) {
    return (
      <Alert type="error" size="xlarge">
        {error?.message}
      </Alert>
    );
  }
  return (
    <>
      <CoreTable
        actions={
          <>
            <Row>
              <CoreTableSettingsButton
                columnDefinitions={columns}
                visibleColumnIds={visibleColumnIds}
                onColumnDefinitionsIdsChange={setVisibleColumnIds}
                selectedPageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
            </Row>
          </>
        }
        fixHeaderRows
        columnDefinitions={columns.filter(
          (col) => visibleColumnIds.indexOf(col.id) !== -1
        )}
        getItemId={(item: CrashLog) => item.id}
        items={data?.crash_steps}
        isLoading={isFetching}
        pagination={
          {
                onPageChange: setPage,
                currentPage: page,
                numberOfPages: 1,
          }
        }
      />
    </>
  );
};
