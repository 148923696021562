import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { CoreToasterProvider } from '@amzn/dots-core-ui';
import {
  CrashModelUri,
  CrashPredictionUri
} from './routes';

import {
  CrashModelSearchPage,
  CrashModelDetailPage
} from './CrashModel';

import {
  CrashPredictionSearchPage,
  CrashPredictionDetailPage
} from './CrashPrediction';


export type AppRouterProps = {
  isRootApp: boolean;
};

export const AppRouter = ({ isRootApp }: AppRouterProps): JSX.Element => {
  return (
    <CoreToasterProvider>
      <Switch>
        <Route path={`${CrashModelUri}/:id`}>
          <CrashModelDetailPage />
        </Route>
        <Route path={`${CrashModelUri}/`}>
          <CrashModelSearchPage />
        </Route>
        <Route path={`${CrashPredictionUri}/:id`}>
          <CrashPredictionDetailPage />
        </Route>
        <Route path={`${CrashPredictionUri}/`}>
          <CrashPredictionSearchPage />
        </Route>
        {isRootApp && <Redirect to={`${CrashModelUri}`} />}
      </Switch>
    </CoreToasterProvider>
  );
};
