import React, { useState } from 'react';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Divider from '@amzn/meridian/divider';
import Text from '@amzn/meridian/text';
import Expander from "@amzn/meridian/expander"
import Alert from '@amzn/meridian/alert';

import { CrashPredictionTable } from './CrashPredictionTable';
import { useClient, useGet } from '$/api';

export const ControlledExpander = ({title, children}: {title: string, children: React.ReactNode}): JSX.Element => {
  const [open, setOpen] = useState(false)
  return <Expander open={open} onChange={setOpen} title={title} children={children}/>
}

export type CrashPredictionDetailProps = {
    crash_model_id: string;
  };

export const CrashPredictionDetailForm = ({
    crash_model_id,
  }: CrashPredictionDetailProps): JSX.Element => {
  // const id = useIdParam();
  const { crash_predictions } = useClient();

  // TODO use the client here

  const {
    data, error, isError,
  } = useGet(crash_predictions.resourceKey, crash_predictions.get, crash_model_id);

  if (isError) {
    return (
      <Alert type="error" size="xlarge">
        {error?.message}
      </Alert>
    );
  }

  // move call here for we can display items
  console.log(crash_model_id);
  return (
    <Column spacing="large">
      <Column spacingInset="medium" type="outline">
        <Heading level={3}>Crash Id</Heading>
        <Divider />
        <Column spacingInset="medium" type="fill">
            <Text tag="pre">{crash_model_id}</Text>
          </Column>
      </Column>
      {data && data.content.map((value, index) => (
        <ControlledExpander title={`Crash#${index} Probability: ${value.total_prob}`}>
          <CrashPredictionTable crashPrediction={value.sequence}/>
        </ControlledExpander>
      ))}
    </Column>
    
  );
};
