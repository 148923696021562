import React from 'react';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
// import { useIdParam } from '$/hooks';
import { CorePage, CorePageTitle } from '$/components';

import { RootUri, CrashModelUri } from '$/features/routes';

export const CrashModelDetailPage = (): JSX.Element => {
  // const id = useIdParam();

  // const crash_model = ["1", "2"]
  return (
    <CorePage
      title={'CrashModel'}
      breadcrumbs={[
        { title: 'KOKO', href: RootUri },
        { title: 'Crash Models', href: CrashModelUri },
      ]}
      header={
        <Row widths={['fill', 'fit']}>
          <Column>
            <CorePageTitle title={'38473873'} />
          </Column>
        </Row>
      }
    >
    </CorePage>
  );
};
