import React from 'react';
import { css } from 'emotion';
import ColorTokens from '@amzn/meridian-tokens/base/color';
import Column from '@amzn/meridian/column';

const bottomBorderStyle = css({
  borderBottom: `1px solid ${ColorTokens.colorGray200}`,
});

export const CorePageHeader = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <Column
      backgroundColor="alternateSecondary"
      className={bottomBorderStyle}
      spacingInset="large"
      spacing="large"
    >
      {children}
    </Column>
  );
};
