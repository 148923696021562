import { HttpClient } from '@amzn/dots-core-ui';
import { BaseApi, BaseGetAllFilter, baseApiFactory } from '../baseApi';
import { CrashModel } from './models';


export type CrashModelId = CrashModel['id'];

export type CrashModelGetAllFilter = BaseGetAllFilter<string> & {
  numlines?: number;
};

export type CrashModelApi = BaseApi<CrashModelId, CrashModel, CrashModelGetAllFilter>;


export const CrashModelClient = (client: HttpClient): CrashModelApi => ({
  ...baseApiFactory(client, 'crash_model'),
});

