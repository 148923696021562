import { HttpClient, TokenCreator } from '@amzn/dots-core-ui';
import { CrashModelApi, CrashModelClient } from './crash_models';
import { CrashPredictionApi, CrashPredictionClient} from './crash_prediction';

export * from './crash_models';
export * from './models';


export interface KokoApi {
  crash_models: CrashModelApi;
  crash_predictions: CrashPredictionApi;
}

export const createKokoClient = (
  kokoApiUrl: string,
  createToken: TokenCreator
): KokoApi => {
  const client = new HttpClient(kokoApiUrl, createToken);
  return {
    crash_models: CrashModelClient(client),
    crash_predictions: CrashPredictionClient(client)
  };
};
