import React from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@amzn/meridian/box';
import Breadcrumb, { BreadcrumbGroup } from '@amzn/meridian/breadcrumb';
import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';
import Alert from '@amzn/meridian/alert';
import { CorePageHeader } from './CorePageHeader';

export type CorePageProps = {
  title?: string;
  isLoading?: boolean;
  isError?: boolean;
  error?: Error | null;
  breadcrumbs?: { title: string; href: string }[];
  header?: React.ReactNode;
  children: React.ReactNode;
};

export const CorePage = ({
  title = '',
  isLoading = false,
  isError = false,
  error = undefined,
  breadcrumbs = [],
  header,
  children,
}: CorePageProps): JSX.Element => {
  const history = useHistory();
  let body = (
    <Column spacing="medium" spacingInset="medium">
      {children}
    </Column>
  );
  if (isLoading) {
    body = (
      <Column alignmentHorizontal="center" spacingInset="xlarge none">
        <Loader type="circular" />
      </Column>
    );
  } else if (isError) {
    body = (
      <Alert type="error" size="xlarge" title="An error occured">
        {error?.message}
      </Alert>
    );
  }
  return (
    <Box>
      <CorePageHeader>
        {title && breadcrumbs.length && !isLoading && (
          <BreadcrumbGroup>
            {breadcrumbs.map(({ title: breadcrumbTitle, href }) => (
              <Breadcrumb key={href} href={href} onClick={history.push}>
                {breadcrumbTitle}
              </Breadcrumb>
            ))}
            <Breadcrumb>{title}</Breadcrumb>
          </BreadcrumbGroup>
        )}
        <Column spacing="small">{header}</Column>
      </CorePageHeader>
      {body}
    </Box>
  );
};
