import React from 'react';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Divider from '@amzn/meridian/divider';
import Text from '@amzn/meridian/text';
import { CrashModelSearchTable } from './CrashModelSearchTable';


export type CrashModelDetailProps = {
    crash_model_id: string;
  };

export const CrashModelDetailForm = ({
    crash_model_id,
  }: CrashModelDetailProps): JSX.Element => {
  // const id = useIdParam();
  
  // Make call here too ??
  console.log(crash_model_id);
  return (

    <Column spacing="large">
      <Column spacingInset="medium" type="outline">
        <Heading level={3}>Crash Id</Heading>
        <Divider />
        <Column spacingInset="medium" type="fill">
            <Text tag="pre">{crash_model_id}</Text>
          </Column>
      </Column>
      <CrashModelSearchTable
          crash_model_id={crash_model_id}
        />
    </Column>
    
  );
};
