import { ColumnDefinition } from '@amzn/dots-core-ui';
import { PredSeqItem } from '$/api';

export const CrashPredictionColumnDefinitions: ColumnDefinition<PredSeqItem>[] = [
  {
    id: 'activityName',
    header: 'Activity',
    item: (crash): string => crash.activityName,
  },
  {
    id: 'appName',
    header: 'App Name',
    item: (crash): string => crash.appName,
  },
  {
    id: 'probability',
    header: 'Probability',
    item: (crash): number => crash.probability,
  },
  {
    id: 'eventDescription',
    header: 'Event Description',
    item: (crash): string => crash.eventDescription,
  },
];
