import React, { useContext, useMemo } from 'react';
import {
  TokenCreator,
  useAuth
} from '@amzn/dots-core-ui';
import {
  createKokoClient,
  KokoApi
} from './clients';

export type ClientContext = {
  kokoApiUrl: string;
} & KokoApi;

export type ClientProviderProps = {
  children: React.ReactNode;
  createToken?: TokenCreator;
  kokoApiUrl: string;
};

const ClientContext = React.createContext<ClientContext | undefined>(undefined);

export const ClientProvider = ({
  children,
  kokoApiUrl,
  createToken,
}: ClientProviderProps): JSX.Element => {
  
  let ct = createToken;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ct = useAuth().createToken;
  } catch (error) {
    // ignored
  }
  if (ct === undefined) {
    throw new Error('no Token Creator provided');
  }
  

  const kokoClient = createKokoClient(
    kokoApiUrl,
    createToken ?? ct
  );

  const value = useMemo(
    () => ({
      ...kokoClient,
      kokoApiUrl
    }),
    [kokoClient, kokoApiUrl]
  );
  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  );
};

export const useClient = (): ClientContext => {
  const context = useContext(ClientContext);
  if (context === undefined) {
    throw new Error('useClient must be used within a ClientProvider');
  }
  return context;
};
