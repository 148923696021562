export const RootUri = '/koko';

const createDetailUri = (root: string) => (id: string | number) =>
  `${root}/${id}`;


export const CrashModelUri = `${RootUri}/crash_model`;
export const CrashModelDetailUri = createDetailUri(CrashModelUri);

export const CrashPredictionUri = `${RootUri}/predicion`;
export const CrashPredictionDetailUri = createDetailUri(CrashModelUri);
