import React, { useCallback, useState }  from 'react';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';

// import { useIdParam } from '$/hooks';
// import { useToaster } from '@amzn/dots-core-ui';

import { CorePage, CorePageTitle } from '$/components';

import { RootUri, CrashModelUri } from '$/features/routes';
import { CrashPredictionDetailForm, CrashPredictionInputForm } from './components';


export const CrashPredictionSearchPage = (): JSX.Element => {
  // const id = useIdParam();
  const [crashId, setCrashId] = useState('');
  const onSubmit = useCallback(
    (input) => {
      setCrashId(input.crash_model_id);
    },
    []
  );
  return (
    <CorePage
      title={'Search for CrashModel Prediction'}
      breadcrumbs={[
        { title: 'KOKO', href: RootUri },
        { title: 'Crash Models', href: CrashModelUri },
      ]}
      header={
        <Row widths={['fill', 'fit']}>
          <Column>
            <CorePageTitle title={'Search for a Crash Model!'} />
          </Column>
        </Row>
      }
    >
    <Row widths="grid-6" alignmentVertical="top" spacing="large">
      <Column>
        <CrashPredictionInputForm onSubmit={onSubmit}/>
      </Column>
    </Row>
    {crashId  && (
        <CrashPredictionDetailForm crash_model_id={crashId}/>
    )}
    
    </CorePage>
  );
};
