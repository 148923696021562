import { HttpClient, PaginatedResponse } from '@amzn/dots-core-ui';
import { CrashPrediction } from './models';



export type CrashPredictionApi = {
    resourceKey: string;
    get: (id: string) => Promise<PaginatedResponse<CrashPrediction>>;
  };


export const CrashPredictionClient = (client: HttpClient): CrashPredictionApi => ({
    resourceKey: 'prediction',
    get: (id: string): Promise<PaginatedResponse<CrashPrediction>> =>
      client.get({ url: `/prediction/${id}` }),
  });
