/* eslint-disable no-param-reassign */
import React, {
  useState
} from 'react';
import Row from '@amzn/meridian/row';

import {
  CoreTable,
  CoreTableSettingsButton,
  useLocalStorage,
} from '@amzn/dots-core-ui';

import { PredSeqItem, useClient } from '$/api';
import { CrashPredictionColumnDefinitions } from './CrashPredictionColumnDefinitions';






export type CoreSearchTableProps = {
  crashPrediction: PredSeqItem[];
};

export const CrashPredictionTable = ({
  crashPrediction
}: CoreSearchTableProps): JSX.Element => {
  const { crash_predictions } = useClient();
  const columns = CrashPredictionColumnDefinitions;

  const [visibleColumnIds, setVisibleColumnIds] = useLocalStorage(
    `${crash_predictions.resourceKey}-list-columns`,
    columns.map((col) => col.id)
  );
  const [pageSize, setPageSize] = useState(1);

  return (
    <>
      <CoreTable
        actions={
          <>
            <Row>
              <CoreTableSettingsButton
                columnDefinitions={columns}
                visibleColumnIds={visibleColumnIds}
                onColumnDefinitionsIdsChange={setVisibleColumnIds}
                selectedPageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
            </Row>
          </>
        }
        fixHeaderRows
        columnDefinitions={columns.filter(
          (col) => visibleColumnIds.indexOf(col.id) !== -1
        )}
        getItemId={(item: PredSeqItem) => item.id}
        items={crashPrediction}
      />
    </>
  );
};
