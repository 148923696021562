import React from 'react';
import Column from '@amzn/meridian/column';
import Alert from '@amzn/meridian/alert';

// import { useIdParam } from '$/hooks';
import {
    CoreInput,
    useForm,
    CoreFormFooter,
} from '@amzn/dots-core-ui';


export type CrashPredictionInputProps = {
    onSubmit: (t: any) => void;
  };
  

export const CrashPredictionInputForm = ({
    onSubmit,
  }: CrashPredictionInputProps): JSX.Element => {
  // const id = useIdParam();
  // Make call here too ??
  const {
    values,
    states,
    errors,
    handlers,
    hasErrors,
    handleSubmit,
    isSubmitting,
    submitState,
  } = useForm({
    crash_model_id: {
      initialValue:  '',
    },
  });
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Column spacingInset="400" spacing="500" maxWidth="100%" width={650}>
        {submitState.status === 'error' && (
          <Alert type="error" title="Error!" size="large">
            {submitState.error?.message}
          </Alert>
        )}
        <CoreInput
          label="CrashId"
          value={values.crash_model_id}
          onChange={handlers.crash_model_id}
          errorMessage={errors.crash_model_id}
          validationState={states.crash_model_id}
        />
        <CoreFormFooter
          isConfirmDisabled={hasErrors || isSubmitting}
          isLoading={isSubmitting}
          onCancelClick={() => console.log('')}
        />
      </Column>
    </form>
  );
};
