import { ColumnDefinition } from '@amzn/dots-core-ui';
import { CrashLog } from '$/api';

export const CrashModelColumnDefinitions: ColumnDefinition<CrashLog>[] = [
  {
    id: 'link',
    header: 'Activity',
    item: (crash): string => crash.activity,
  },
  {
    id: 'action',
    header: 'Action',
    item: (crash): string => crash.action,
  },
  {
    id: 'timestamp',
    header: 'Time',
    item: (crash): number => crash.timestamp,
  },
  {
    id: 'message',
    header: 'Message',
    item: (crash): string => crash.message,
  },
];
