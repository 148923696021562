import { UserManagerSettings } from 'oidc-client';
import {
  lassoProductionConfiguration,
} from './api/auth';

export interface Environment {
  hosts: string[];
  kokoApiUrl: string;
  userManagerSettings: UserManagerSettings;
}

const environments: Environment[] = [
  {
    // Alpha
    hosts: [
      'alpha.koko.lab126.amazon.dev',
    ],
    kokoApiUrl: 'https://api.koko.lab126.amazon.dev/analysis',
    userManagerSettings: lassoProductionConfiguration,
  },
  {
    // Beta
    hosts: [
      'localhost',
      'xevan.aka.corp.amazon.com',
      'beta.koko.lab126.amazon.dev',
    ],
    kokoApiUrl: 'https://api.koko.lab126.amazon.dev/analysis',
    userManagerSettings: lassoProductionConfiguration,
  },
  {
    // Gamma
    hosts: [
      'gamma.koko.lab126.amazon.dev',
    ],
    kokoApiUrl: 'https://api.koko.lab126.amazon.dev/analysis',
    userManagerSettings: lassoProductionConfiguration,
  },
  {
    // Prod
    hosts: [
      'koko.lab126.amazon.dev',
    ],
    kokoApiUrl: 'https://api.koko.lab126.amazon.dev/analysis',
    userManagerSettings: lassoProductionConfiguration,
  },
];

const match = environments.filter(
  (config) => config.hosts.indexOf(window.location.hostname) > -1
);
if (match.length === 0) {
  throw new Error(`${window.location.hostname} is not configured`);
}

export const [environment] = match;
